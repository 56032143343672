import React,{useState,useCallback} from "react";
import "./Modal.css";
import MySurvey  from './surveyDisplay/mapsurveyone' ; 


function Modal( {setOpenModal,setMarkers,marker,info,setInfo,hindi} ) {
  // console.log(info);
  // setInfo(false) ; 
  const onClickHandler=(e)=>{
     setMarkers((current)=>{
    // console.log(marker.pos) ; 
      let arr=[...current] ; 
      arr.splice(marker.pos,1) ; 
      for (let i = 0; i < arr.length; i++) { arr[i].pos=i ;  }
      return arr ; 
     })      
     setOpenModal(false) ; 
  } ;



  return (  
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="titleCloseBtn">
          <button onClick={() => { setOpenModal(false); setInfo(false);/*console.log(marker);*/ }} > X </button>
        </div>
        <div className="title">
        {info ?  
       hindi ? <h4 style={{marginBottom:0}}>जरूरी निर्देश</h4> :<h4 style={{marginBottom:0}}>Instructions</h4> 
        :
        <div style={{display:'flex',justifyContent:'center'}}>
        <h5>Marker : {marker.pos}</h5>
        <button style={{color:'rgb(255,255,255)',
                    				appearance:'none',
                    				border:'none',
                    				fontFamily:'inherit',
                    				fontWeight:'bold',
                    				outline:'none',
                    				backgroundColor:'rgb(255, 115, 161)',
                    				borderRadius:'1em',
                    				cursor:'pointer',
                    				fontSize:'.6em',
                    				padding:'0.5em 1em 0.6em',
                    				textAlign:'left'}}
            onClick={(e)=>{onClickHandler(e)}}>Delete this marker</button>
          </div>
        }
        </div>
        <div className="body">
        <main>
      {info ?
       <div style={{    width:'70vw',
                        height:'50vh',
                        display:'flex',
                        justifyContent:'space-around !important',
                        alignItems:'flex-start !important',
                        textAlign:'justify',
                        flexDirection:'column',
                        fontSize:'.7em',
                        borderRadius:'1em',
                        // backgroundColor:'rgb(245, 164, 103)',
                        // background: 'rgba(0, 0, 0, 0.5)', 
                        backgroundImage:"url(/bg_image.png)",
                        // opacity:'0.5',  
                        padding:'1.2em',
                        // color:'#FFF'
                        }}>
        { hindi? <ul>
                <li style={{paddingBottom:'.5em'}}>मैप आधारित सर्वे भरने के लिए, कृपया पहले अपना घर या निकटतम सड़क जंक्शन मार्क करें।</li>
                <li style={{paddingBottom:'.5em'}}>उसके बाद आप जिन पार्क, मैदान, खेल का मैदान, खेल परिसर, और कोई अन्य मनोरंजक खुली 
		जगह पर अक्सर अपने दोस्तों के साथ जाते हैं और न की बड़ों के साथ, ऐसे 5 जगह तक मार्क कर सकते हैं। </li>
                <li style={{paddingBottom:'.5em'}}>हर पार्क, मैदान, खेल का मैदान, खेल परिसर, और कोई अन्य मनोरंजक खुली जगह का 
		विवरण भरने के लिए प्रत्येक मार्कर पर क्लिक करें।</li>
		<li style={{paddingBottom:'.5em'}}>ध्यान रहे की मैप पर स्थान मार्क करने के लिए ज़ूम स्तर 17 या अधिक होना चाहिए।</li>

           </ul>
        :    <ul >
                <li style={{paddingBottom:'1em'}}>Please mark your home location or nearest road junction.</li>
                <li style={{paddingBottom:'1em'}}>Please mark Recreational Open Space (ROS: Parks, playfields, sports complex and other such open spaces) on map you visit most frequently without adult supervision.</li>
                <li style={{paddingBottom:'1em'}}>You can mark upto 5 ROS. Please click on each marked ROS to fill in their details.</li>
                <li style={{paddingBottom:'1em'}}>You will be able to mark the required places on map only when the zoom level is 17 or more.</li>
           </ul>
        }
       </div> 
      : 
      <MySurvey setMarkers={setMarkers} marker={marker} setOpenModal={setOpenModal} hindi={hindi} />}
      </main>
        </div>
      </div>
    </div>
  );
}

export default Modal;
