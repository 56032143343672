
import {useState, useCallback } from 'react';

// Modern theme
import 'survey-core/modern.min.css';
// import 'survey-core/defaultV1.min.css' ; 
// Default V2 theme
import 'survey-core/defaultV2.min.css';
import { StylesManager, Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import {json} from '../surveys/questiontwo' ; 
import {jsonHindi} from '../surveys/questiontwoHindi' ; 
// const SURVEY_ID = 1;
// StylesManager.applyTheme("modern");
StylesManager.applyTheme("defaultV2");


const MySurvey=(prop)=>{
	const survey = new Model(prop.hindi?jsonHindi:json);
	survey.showCompletedPage=false ; 
	survey.completeText=(prop.hindi?"पूर्ण करें":"Done") ;
	// console.log(prop.data.question14);
	// console.log(survey.getQuestionByName("question15").getPropertyValue("type")); 
	// console.log(survey.getQuestionByName("question15").getPropertyValue("name")); 
	// console.log(survey.getQuestionByName("question15").getPropertyValue("visibleIf")); 
	if(prop.data.question14==1){
	survey.getQuestionByName("question22").setPropertyValue("visible",false); 
	survey.getQuestionByName("question23").setPropertyValue("visible",false); 
	survey.getQuestionByName("question24").setPropertyValue("visible",false); 
	survey.getQuestionByName("question25").setPropertyValue("visible",false); 
	survey.getQuestionByName("question26").setPropertyValue("visible",false); 
	survey.getQuestionByName("question27").setPropertyValue("visible",false); 
	survey.getQuestionByName("question42").setPropertyValue("visible",false); 
	}
	console.log(survey.getQuestionByName("question15").getPropertyValue("visible")); 
	// survey.getQuestionByName("question15").

	// survey.data={
	// "question15":2,//What rules do your parents have regarding your going out?
	// "question16":1,//Can you explore on your own?
	// "question17":1,//Do you have friends in  your neighborhood?
	// "question18":2,//I know most of my neighbors
	// "question19":1,//How would you describe your neighbourhood?
	// "question20":3,//There is garbage, litter, or broken glass in the street or road, on sidewalks in my neighborhood.
	// "question21":4,//There are “vacant/shabby buildings and houses in my neighborhood.
	// "question22":3,//Recreational spaces I visit are well maintained
	// "question23":1,//Recreational open spaces I visit are safe to visit any time of the day
	// "question24":1,//Recreational spaces I visit have good facilities and amenities
	// "question25":1,//Recreational spaces I visit have organised activities (religious, cultural, yoga, plantation and other such activities).
	// "question26":1,//Do you participate in those activities ?
	// "question27":4,//Recreational spaces I visit are thermally comfortable
	// "question28":1,//My relationship to nature is an important part of who I am.
	// "question29":1,//I always think about how my actions affect the environment.
	// "question30":2,//I take notice of wildlife wherever I am.
	// "question31":3,//I feel very connected to all living things and the earth.
	// "question32":4,//My ideal vacation spot would be a remote, wilderness area.
	// "question33":1,//How much time per day do you typically use internet after school?
	// "question34":1,//How much time per day do you typically use personal computers after school?
	// "question35":2,//How much time each day do you typically use smartphones after school?
	// "question36":2,//How much time each day do you typically watch TV after school?
	// "question37":3,//How much time each day do you typically play video games?
	// "question38":4,//How frequently per week do you engage in after-school private lessons or enrichment programmes like academic training, music courses, community sports, foreign languages etc.?
	// "question39":3,//Are there any adults in your family (such as sibling, parents or grandparents) who often tells you how fun and interesting it is to explore Recreational open spaces
	// "question40":2,//Are there any adults in your family (such as sibling, parents or grandparents) who often prevents you from visiting Recreational open spaces?
	// "question41":1,//State your physical activity level 
	// "question42": {'Row1': '2', 'Row2': '1'},//Motivation for physical activity
	// "question43": {'Row1': '1', 'Row2': '1', 'Row3': '1'},//Physical Health
	// "question44":50,//Weight
	// "question45":165,//Height
	// "question46": {'Row1': '3', 'Row2': '3', 'Row3': '4', 'Row4': '4', 'Row5': '2','Row6': '4', 'Row7': '1'},//Mental Health
	// };

	const onCompletePage = useCallback((sender) => {
		let data={...prop.data,...sender.data} ; 
		prop.showCompletedPage(data) ; 
	}, []);
      
	survey.onComplete.add(onCompletePage);
	return <Survey model={survey} />
      }
      export default MySurvey;