export const jsonHindi={
	// "title":"Accessibility of open spaces",
	// "description":"Accessibility of open spaces  " ,
	// "logoPosition": "right",
	// "logo":"/manitLogo.png",
	// "logoWidth":60,
	// "logoHeight":60,
	// "showQuestionNumbers":"off",
	"questionStartIndex":"15",
	"pages": [
	 {
	  "name": "page1",
	  "elements": [
		   {
		    "type": "radiogroup",
		    "name": "question15",
		    "title": "आपके बाहर जाने को लेकर आपके माता-पिता के क्या नियम हैं?",
		    "isRequired": true,
		    "choices": [
		     {
		      "value": 1,
		      "text": "सीमा तय करते हैं"
		     },
		     {
		      "value": 2,
		      "text": "सीमा + अनुमति"
		     },
		     {
		      "value": 3,
		      "text": "कोई सीमा नहीं"
		     },
		     {
		      "value": 4,
		      "text": "कोई सीमा नहीं + अनुमति"
		     }
		    ]
		   },
		   {
		    "type": "radiogroup",
		    "name": "question16",
		    "title": "क्या आप अकेले घूम सकते हैं?",
		    "isRequired": true,
		    "choices": [
		     {
		      "value": 1,
		      "text": "नहीं"
		     },
		     {
		      "value": 2,
		      "text": "हाँ"
		     }
		    ]
		   },
		   {
		    "type": "radiogroup",
		    "name": "question17",
		    "title": "क्या पड़ोस में आपके दोस्त हैं?",
		    "isRequired": true,
		    "choices": [
		     {
		      "value": 1,
		      "text": "नहीं"
		     },
		     {
		      "value": 2,
		      "text": "हाँ"
		     }
		    ]
		   },
		   {
		    "type": "radiogroup",
		    "name": "question18",
		    "title": "मैं अपने ज़्यादातर पड़ोसियों को जानता हूँ ",
		    "isRequired": true,
		    "choices": [
		     {
		      "value": 1,
		      "text": "असहमत"
		     },
		     {
		      "value": 2,
		      "text": "थोड़ा सहमत"
		     },
		     {
		      "value": 3,
		      "text": "पूर्णतः सहमत"
		     }
		    ]
		   },
		   {
		    "type": "radiogroup",
		    "name": "question19",
		    "title": "आप अपनी कॉलोनी का वर्णन कैसे करेंगे?",
		    "isrequired": true,
		    "choices": [
		     {
		      "value": 1,
		      "text": "व्यस्त"
		     },
		     {
		      "value": 2,
		      "text": "दोस्ताना"
		     },
		     {
		      "value": 3,
		      "text": "हरी भरी और प्रकृति के करीब"
		     },
		     {
		      "value": 4,
		      "text": "काफी शोर शराबा"
		     },
		     {
		      "value": 5,
		      "text": "शांत"
		     }
		    ]
		   },
		//    {
		//     "type": "radiogroup",
		//     "name": "question19",
		//     "title": "मेरे पड़ोस में अच्छे पार्क, खेलने के स्थान और खेल के मैदान हैं। ",
		//     "isRequired": true,
		//     "choices": [
		// 	{
		// 	 "value": 1,
		// 	 "text": "पूर्णतः असहमत"
		// 	},
		// 	{
		// 	 "value": 2,
		// 	 "text": "असहमत"
		// 	},
		// 	{
		// 	 "value": 3,
		// 	 "text": "कोई राय नहीं "
		// 	},
		// 	{
		// 	 "value": 4,
		// 	 "text": "इस बात से सहमत"
		// 	},
		// 	{
		// 	 "value": 5,
		// 	 "text": "पूर्णतः सहमत"
		// 	}
		//        ]
		//    },
		   {
		    "type": "radiogroup",
		    "name": "question20",
		    "title": "मेरे पड़ोस में \"कूड़ा-कचरा, गली या सड़क के फुटपाथों पर टूटे हुए कांच\" है।",
		    "isRequired": true,
		    "choices": [
			{
			 "value": 1,
			 "text": "पूर्णतः असहमत"
			},
			{
			 "value": 2,
			 "text": "असहमत"
			},
			{
			 "value": 3,
			 "text": "कोई राय नहीं "
			},
			{
			 "value": 4,
			 "text": "इस बात से सहमत"
			},
			{
			 "value": 5,
			 "text": "पूर्णतः सहमत"
			}
		       ]
		   },
		   {
		    "type": "radiogroup",
		    "name": "question21",
		    "title": "मेरे पड़ोस में खाली/जर्जर इमारतें और घर हैं।",
		    "isRequired": true,
		    "choices": [
			{
				"value": 1,
				"text": "पूर्णतः असहमत"
			       },
			       {
				"value": 2,
				"text": "असहमत"
			       },
			       {
				"value": 3,
				"text": "कोई राय नहीं "
			       },
			       {
				"value": 4,
				"text": "इस बात से सहमत"
			       },
			       {
				"value": 5,
				"text": "पूर्णतः सहमत"
			       }
		       ]
		   },
		   {
		    "type": "radiogroup",
		    "name": "question22",
		    "title": "मेरे द्वारा गए पार्क/खेलने के स्थान का रख रखाव अच्छा है।",
		    "isRequired": true,
		    "choices": [
			{
				"value": 1,
				"text": "पूर्णतः असहमत"
			       },
			       {
				"value": 2,
				"text": "असहमत"
			       },
			       {
				"value": 3,
				"text": "कोई राय नहीं "
			       },
			       {
				"value": 4,
				"text": "इस बात से सहमत"
			       },
			       {
				"value": 5,
				"text": "पूर्णतः सहमत"
			       }
		       ]
		   },
		  {
		    "type": "radiogroup",
		    "name": "question23",
		    "title": "मेरे द्वारा गए पार्क/खेलने के स्थान हर समय सुरक्षित रहता है ।",
		    "isRequired": true,
		    "choices": [
			{
				"value": 1,
				"text": "पूर्णतः असहमत"
			       },
			       {
				"value": 2,
				"text": "असहमत"
			       },
			       {
				"value": 3,
				"text": "कोई राय नहीं "
			       },
			       {
				"value": 4,
				"text": "इस बात से सहमत"
			       },
			       {
				"value": 5,
				"text": "पूर्णतः सहमत"
			       }
		       ]
		   },
		   {
		    "type": "radiogroup",
		    "name": "question24",
		    "title": "मेरे द्वारा गए पार्क/खेलने के स्थान में अच्छी सुविधाएं हैं|",
		    "isRequired": true,
		    "choices": [
			{
				"value": 1,
				"text": "पूर्णतः असहमत"
			       },
			       {
				"value": 2,
				"text": "असहमत"
			       },
			       {
				"value": 3,
				"text": "कोई राय नहीं "
			       },
			       {
				"value": 4,
				"text": "इस बात से सहमत"
			       },
			       {
				"value": 5,
				"text": "पूर्णतः सहमत"
			       }
		       ]
		   },
   		{
   		   "type": "radiogroup",
   		   "name": "question25",
   		   "title": "मेरे द्वारा गए पार्क/खेलने के स्थान में गतिविधियाँ आयोजित होती हैं जैसे (धार्मिक, सांस्कृतिक, योग, वृक्षारोपण और ऐसी अन्य गतिविधियाँ)।",
   		   "isRequired": true,
   		   "choices": [ {"value":1,"text":"हाँ"},{"value":2,"text":"नहीं"} ],
   		   "colCount": 0
   		 },
   		 {
   		   "type": "radiogroup",
   		   "name": "question26",
   		   "title": "क्या आप उन गतिविधियों में भाग लेते हैं?",
   		   "visibleIf": "{question25} = 1",
   		   "isRequired": true,
   		   "choices": [ {"value":1,"text":"हाँ"},{"value":2,"text":"नहीं"} ],
   		   "colCount": 0
   		 },
                 {
		    "type": "radiogroup",
		    "name": "question27",
		    "title": "मेरे द्वारा गए पार्क/खेलने के स्थान का तापमान आरामदायक रहता है।",
		    "isRequired": true,
		    "choices": [
			{
				"value": 1,
				"text": "पूर्णतः असहमत"
			       },
			       {
				"value": 2,
				"text": "असहमत"
			       },
			       {
				"value": 3,
				"text": "कोई राय नहीं "
			       },
			       {
				"value": 4,
				"text": "इस बात से सहमत"
			       },
			       {
				"value": 5,
				"text": "पूर्णतः सहमत"
			       }
		       ]
		   },
		   {
		    "type": "radiogroup",
		    "name": "question28",
		    "title": "प्रकृति से मेरा संबंध मेरी पहचान का एक महत्वपूर्ण हिस्सा है।",
		    "isRequired": true,
		    "choices": [
			{
				"value": 1,
				"text": "पूर्णतः असहमत"
			       },
			       {
				"value": 2,
				"text": "असहमत"
			       },
			       {
				"value": 3,
				"text": "कोई राय नहीं "
			       },
			       {
				"value": 4,
				"text": "इस बात से सहमत"
			       },
			       {
				"value": 5,
				"text": "पूर्णतः सहमत"
			       }
		    ]
		   },
		   {
		    "type": "radiogroup",
		    "name": "question29",
		    "title": "मेरे कार्यो से पर्यावरण पर पड़ने वाले प्रभावों की मुझे चिंता रहती है|",
		    "isRequired": true,
		    "choices": [
			{
				"value": 1,
				"text": "पूर्णतः असहमत"
			       },
			       {
				"value": 2,
				"text": "असहमत"
			       },
			       {
				"value": 3,
				"text": "कोई राय नहीं "
			       },
			       {
				"value": 4,
				"text": "इस बात से सहमत"
			       },
			       {
				"value": 5,
				"text": "पूर्णतः सहमत"
			       }
		    ]
		   },
		   {
		    "type": "radiogroup",
		    "name": "question30",
		    "title": "मैं जहां भी हूं, अपने आस पास के वन्यजीवन की तरफ ध्यान देता/देती हूँ|",
		    "isRequired": true,
		    "choices": [
			{
				"value": 1,
				"text": "पूर्णतः असहमत"
			       },
			       {
				"value": 2,
				"text": "असहमत"
			       },
			       {
				"value": 3,
				"text": "कोई राय नहीं "
			       },
			       {
				"value": 4,
				"text": "इस बात से सहमत"
			       },
			       {
				"value": 5,
				"text": "पूर्णतः सहमत"
			       }
		    ]
		   },
		   {
		    "type": "radiogroup",
		    "name": "question31",
		    "title": "मैं सभी जीवित चीजों और पृथ्वी से बहुत जुड़ा हुआ महसूस करता/करती हूं।",
		    "isRequired": true,
		    "choices": [
			{
				"value": 1,
				"text": "पूर्णतः असहमत"
			       },
			       {
				"value": 2,
				"text": "असहमत"
			       },
			       {
				"value": 3,
				"text": "कोई राय नहीं "
			       },
			       {
				"value": 4,
				"text": "इस बात से सहमत"
			       },
			       {
				"value": 5,
				"text": "पूर्णतः सहमत"
			       }
		    ]
		   },
		   {
		    "type": "radiogroup",
		    "name": "question32",
		    "title": "मेरा आदर्श अवकाश स्थान एक दूरस्थ, सूंदर प्राकृतिक क्षेत्र होगा।",
		    "isRequired": true,
		    "choices": [
			{
				"value": 1,
				"text": "पूर्णतः असहमत"
			       },
			       {
				"value": 2,
				"text": "असहमत"
			       },
			       {
				"value": 3,
				"text": "कोई राय नहीं "
			       },
			       {
				"value": 4,
				"text": "इस बात से सहमत"
			       },
			       {
				"value": 5,
				"text": "पूर्णतः सहमत"
			       }
		    ]
		   },
		   {
		    "type": "radiogroup",
		    "name": "question33",
		    "title": "आप आमतौर पर स्कूल के बाद प्रति दिन कितने समय इंटरनेट का उपयोग करते हैं?",
		    "isRequired": true,
		    "choices": [
		     {
		      "value": 1,
		      "text": "	नहीं करते हैं"
		     },
		     {
		      "value": 2,
		      "text": "दिन में 11-30 मिनट"
		     },
		     {
		      "value": 3,
		      "text": "दिन में 31-60 मिनट"
		     },
		     {
		      "value": 4,
		      "text": "दिन में एक या दो घंटे"
		     },
		     {
		      "value": 5,
		      "text": "दिन में दो घंटे से अधिक"
		     }
		    ]
		   },
		   {
		    "type": "radiogroup",
		    "name": "question34",
		    "title": "स्कूल के बाद आप आमतौर पर प्रति दिन कितना समय कंप्यूटर का उपयोग करते हैं? ",
		    "isRequired": true,
		    "choices": [
			{
				"value": 1,
				"text": "नहीं करते हैं"
			       },
			       {
				"value": 2,
				"text": "दिन में 11-30 मिनट"
			       },
			       {
				"value": 3,
				"text": "दिन में 31-60 मिनट"
			       },
			       {
				"value": 4,
				"text": "दिन में एक या दो घंटे"
			       },
			       {
				"value": 5,
				"text": "दिन में दो घंटे से अधिक"
			       }
		    ]
		   },
		   {
		    "type": "radiogroup",
		    "name": "question35",
		    "title": "स्कूल के बाद आप आमतौर पर प्रति दिन कितना समय स्मार्टफोन का उपयोग करते हैं?",
		    "isRequired": true,
		    "choices": [
			{
				"value": 1,
				"text": "नहीं करते हैं"
			       },
			       {
				"value": 2,
				"text": "दिन में 11-30 मिनट"
			       },
			       {
				"value": 3,
				"text": "दिन में 31-60 मिनट"
			       },
			       {
				"value": 4,
				"text": "दिन में एक या दो घंटे"
			       },
			       {
				"value": 5,
				"text": "दिन में दो घंटे से अधिक"
			       }
		    ]
		   },
		   {
		    "type": "radiogroup",
		    "name": "question36",
		    "title": "स्कूल के बाद आप आम तौर पर हर दिन कितना समय टीवी देखते हैं?",
		    "isRequired": true,
		    "choices": [
			{
				"value": 1,
				"text": "नहीं देखते हैं"
			       },
			       {
				"value": 2,
				"text": "दिन में 11-30 मिनट"
			       },
			       {
				"value": 3,
				"text": "दिन में 31-60 मिनट"
			       },
			       {
				"value": 4,
				"text": "दिन में एक या दो घंटे"
			       },
			       {
				"value": 5,
				"text": "दिन में दो घंटे से अधिक"
			       }
		    ]
		   },
		   {
		    "type": "radiogroup",
		    "name": "question37",
		    "title": "आप आम तौर पर हर दिन कितना समय वीडियो गेम खेलते हैं?",
		    "isRequired": true,
		    "choices": [
			{
				"value": 1,
				"text": "नहीं खेलते हैं"
			       },
			       {
				"value": 2,
				"text": "दिन में 11-30 मिनट"
			       },
			       {
				"value": 3,
				"text": "दिन में 31-60 मिनट"
			       },
			       {
				"value": 4,
				"text": "दिन में एक या दो घंटे"
			       },
			       {
				"value": 5,
				"text": "दिन में दो घंटे से अधिक"
			       }
		    ]
		   },
		   {
		    "type": "radiogroup",
		    "name": "question38",
		    "title": "आप प्रति सप्ताह कितनी बार स्कूल के बाद के निजी पठन-पाठन या शैक्षणिक प्रशिक्षण जैसे संगीत पाठ्यक्रम, सामुदायिक खेल, विदेशी भाषाओं आदि जैसे समृद्ध कार्यक्रम सीखने जाते हैं?",
		    "isRequired": true,
		    "choices": [
		     {
		      "value": 1,
		      "text": "नहीं जाते हैं"
		     },
		     {
		      "value": 2,
		      "text": "एक सप्ताह में एक बार"
		     },
		     {
		      "value": 3,
		      "text": "हफ़्ते में दो या तीन बार"
		     },
		     {
		      "value": 4,
		      "text": "सप्ताह में चार या पांच बार"
		     },
		     {
		      "value": 5,
		      "text": "लगभग हर दिन"
		     }
		    ]
		   },
		   {
		    "type": "radiogroup",
		    "name": "question39",
		    "title": "क्या आपके परिवार में कोई वयस्क (जैसे भाई-बहन, माता-पिता या दादा-दादी) हैं जो अक्सर आपको बताते हैं कि पार्क, खेलने के स्थान और खेल के मैदान में जाना कितना मजेदार और दिलचस्प है?",
		    "isRequired": true,
		    "choices": [
		     {
		      "value": 1,
		      "text": "कोई नहीं"
		     },
		     {
		      "value": 2,
		      "text": "एक व्यक्ति"
		     },
		     {
		      "value": 3,
		      "text": "एक से अधिक व्यक्ति"
		     }
		    ]
		   },
		   {
		    "type": "radiogroup",
		    "name": "question40",
		    "title": "क्या आपके परिवार में कोई वयस्क (जैसे भाई-बहन, माता-पिता या दादा-दादी) हैं जो अक्सर आपको पार्क, खेलने के स्थान और खेल के मैदानों पर जाने से रोकते हैं?",
		    "isRequired": true,
		    "choices": [
			{
				"value": 1,
				"text": "कोई नहीं"
			       },
			       {
				"value": 2,
				"text": "एक व्यक्ति"
			       },
			       {
				"value": 3,
				"text": "एक से अधिक व्यक्ति"
			       }
		    ]
		   },
		   {
			"type": "radiogroup",
			"name": "question41",
			"title": "अपना शारीरिक गतिविधि स्तर बताएं  ",
			"isRequired": true,
			"choices": [
			 {
			  "value": 1,
			  "text": "कम (बहुत धीमी गति से चलना/टहलना, आराम करना/बैठना, खड़े होना, योग)"
			 },
			 {
			  "value": 2,
			  "text": "मध्यम (मध्यम गति से चलना, तेज गति से चलना, धीरे-धीरे साइकिल चलाना, मध्यम तीव्रता वाले खेल, खेल के मैदान/फिटनेस उपकरण का उपयोग करना)"
			 },
			 {
			  "value": 3,
			  "text": "उच्च (जॉगिंग या दौड़ना, तेज साइकिल चलाना, उच्च तीव्रता वाला खेल, बूट कैंप / फिटनेस प्रोग्राम)"
			 }
			]
		       },
		{
     "type": "matrix",
     "name": "question42",
     "title": "शारीरिक गतिविधि के लिए प्रेरणा",
     "isRequired": true,
     "columns": [
      {
       "value": 0,
       "text": "नहीं"
      },
      {
       "value": 1,
       "text": "हाँ"
      }
     ],
     "rows": [
      {
       "value": "Row1",
       "text": "पार्क, खेलने के स्थान और खेल के मैदानों  में शारीरिक गतिविधि उपकरण हैं।"
      },
      {
       "value": "Row2",
       "text": "मैं बहुत से लोगों को पार्क, खेलने के स्थान और खेल के मैदानों में शारीरिक गतिविधियां करते देखता/देखती हूं जैसे चलना, टहलना, साइकिल चलाना, या खेल खेलना।"
      }
     ]
    },
    {
     "type": "matrix",
     "name": "question43",
     "title": "शारीरिक स्वास्थ्य",
     "columns": [
      {
       "value": 0,
       "text": "नहीं"
      },
      {
       "value": 1,
       "text": "हाँ"
      }
     ],
     "rows": [
      {
       "value": "Row1",
       "text": "मधुमेह"
      },
      {
       "value": "Row2",
       "text": "रक्त चाप"
      },
      {
       "value": "Row3",
       "text": "दमा"
      }
     ],
      "isAllRowRequired": true
    },
	   {
	    "type": "text",
	    "name": "question44",
	    "title": "वजन (किग्रा में)",
	    "isRequired": true
	   },
	   {
	    "type": "text",
	    "name": "question45",
	    "title": "लम्बाई (सेंटीमीटर मे)",
	    "isRequired": true
	   },
    {
     "type": "matrix",
     "name": "question46",
     "title": "मानसिक स्वास्थ्य (नीचे दिए गए उन विकल्पों का चयन करें जो पिछले 2 सप्ताहों में आपके अनुभव का सबसे अच्छा वर्णन करते हैं)",
     "columns": [
      {
       "value": 1,
       "text": "कभी नहीं"
      },
      {
       "value": 2,
       "text": "बहुत कम "
      },
      {
       "value": 3,
       "text": "कभी कभी"
      },
      {
       "value": 4,
       "text": "अक्सर"
      },
      {
       "value": 5,
       "text": "हमेशा"
      }
     ],
     "rows": [
      {
       "value": "Row1",
       "text": "मैं भविष्य को लेकर आशावादी महसूस कर रहा/रही हूं।"
      },
      {
       "value": "Row2",
       "text": "मैं दूसरों के लिए उपयोगी महसूस कर रहा/रही हूं।"
      },
      {
       "value": "Row3",
       "text": "मैं आराम महसूस कर रहा/रही हूं।"
      },
      {
       "value": "Row4",
       "text": "मैं समस्याओं से अच्छे से निपट रहा/रही हूं।"
      },
      {
       "value": "Row5",
       "text": "मैं स्पष्ट रूप से सोच पा रहा/रही हूँ।"
      },
      {
       "value": "Row6",
       "text": "मैं अन्य लोगों के करीब महसूस कर रहा/रही हूं।"
      },
      {
       "value": "Row7",
       "text": "मैं चीजों के बारे में अपना मन बनाने में सक्षम हूं।"
      }
     ],
     "isAllRowRequired": true
    }
		  ]
		 }
		 
		]
	       };