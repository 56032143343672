export const json={
	// "title":"Accessibility of open spaces",
	// "description":"Accessibility of open spaces  " ,
	// "logoPosition": "right",
	// "logo":"/manitLogo.png",
	// "logoWidth":60,
	// "logoHeight":60,
	// "showQuestionNumbers":"off",
	"questionStartIndex":"15",
	"pages": [
	 {
	  "name": "page1",
	  "elements": [
		   {
		    "type": "radiogroup",
		    "name": "question15",
		    "title": "What rules do your parents have regarding your going out?",
		    "isRequired": true,
		    "choices": [
		     {
		      "value": 1,
		      "text": "Set limits"
		     },
		     {
		      "value": 2,
		      "text": "Limits + permission"
		     },
		     {
		      "value": 3,
		      "text": "No limits"
		     },
		     {
		      "value": 4,
		      "text": "No limits + permission"
		     }
		    ]
		   },
		   {
		    "type": "radiogroup",
		    "name": "question16",
		    "title": "Can you explore on your own?",
		    "isRequired": true,
		    "choices": [
		     {
		      "value": 1,
		      "text": "No"
		     },
		     {
		      "value": 2,
		      "text": "Yes"
		     }
		    ]
		   },
		   {
		    "type": "radiogroup",
		    "name": "question17",
		    "title": "Do you have friends in  your neighborhood?",
		    "isRequired": true,
		    "choices": [
		     {
		      "value": 1,
		      "text": "No"
		     },
		     {
		      "value": 2,
		      "text": "Yes"
		     }
		    ]
		   },
		   {
		    "type": "radiogroup",
		    "name": "question18",
		    "title": "I know most of my neighbors",
		    "isRequired": true,
		    "choices": [
		     {
		      "value": 1,
		      "text": "Disagree"
		     },
		     {
		      "value": 2,
		      "text": "Somewhat agree"
		     },
		     {
		      "value": 3,
		      "text": "Strongly agree"
		     }
		    ]
		   },
		   {
		    "type": "radiogroup",
		    "name": "question19",
		    "title": "How would you describe your neighbourhood?",
		    "isRequired": true,
		    "choices": [
		     {
		      "value": 1,
		      "text": "Busy"
		     },
		     {
		      "value": 2,
		      "text": "Friendly"
		     },
		     {
		      "value": 3,
		      "text": "Lots of nature around"
		     },
		     {
		      "value": 4,
		      "text": "Noisy"
		     },
		     {
		      "value": 5,
		      "text": "Quiet"
		     }
		    ]
		   },
		//    {
		//     "type": "radiogroup",
		//     "name": "question20",
		//     "title": "There are good parks, play spaces and playgrounds in my neighborhood.",
		//     "isRequired": true,
		//     "choices": [
		// 	{
		// 	 "value": 1,
		// 	 "text": "Strongly disagree"
		// 	},
		// 	{
		// 	 "value": 2,
		// 	 "text": "Disagree"
		// 	},
		// 	{
		// 	 "value": 3,
		// 	 "text": "Neutral"
		// 	},
		// 	{
		// 	 "value": 4,
		// 	 "text": "Agree"
		// 	},
		// 	{
		// 	 "value": 5,
		// 	 "text": "Strongly agree"
		// 	}
		//        ]
		//    },
		   {
		    "type": "radiogroup",
		    "name": "question20",
		    "title": "There is garbage, litter, or broken glass in the street or road, on sidewalks in my neighborhood.",
		    "isRequired": true,
		    "choices": [
			{
			 "value": 1,
			 "text": "Strongly disagree"
			},
			{
			 "value": 2,
			 "text": "Disagree"
			},
			{
			 "value": 3,
			 "text": "Neutral"
			},
			{
			 "value": 4,
			 "text": "Agree"
			},
			{
			 "value": 5,
			 "text": "Strongly agree"
			}
		       ]
		   },
		   {
		    "type": "radiogroup",
		    "name": "question21",
		    "title": "There are vacant/shabby buildings and houses in my neighborhood.",
		    "isRequired": true,
		    "choices": [
			{
			 "value": 1,
			 "text": "Strongly disagree"
			},
			{
			 "value": 2,
			 "text": "Disagree"
			},
			{
			 "value": 3,
			 "text": "Neutral"
			},
			{
			 "value": 4,
			 "text": "Agree"
			},
			{
			 "value": 5,
			 "text": "Strongly agree"
			}
		       ]
		   },
		   {
		    "type": "radiogroup",
		    "name": "question22",
		    "title": "Recreational spaces I visit are well maintained",
		    "isRequired": true,
		    "choices": [
			{
			 "value": 1,
			 "text": "Strongly disagree"
			},
			{
			 "value": 2,
			 "text": "Disagree"
			},
			{
			 "value": 3,
			 "text": "Neutral"
			},
			{
			 "value": 4,
			 "text": "Agree"
			},
			{
			 "value": 5,
			 "text": "Strongly agree"
			}
		       ]
		   },
		  {
		    "type": "radiogroup",
		    "name": "question23",
		    "title": "Recreational open spaces I visit are safe to visit any time of the day",
		    "isRequired": true,
		    "choices": [
			{
			 "value": 1,
			 "text": "Strongly disagree"
			},
			{
			 "value": 2,
			 "text": "Disagree"
			},
			{
			 "value": 3,
			 "text": "Neutral"
			},
			{
			 "value": 4,
			 "text": "Agree"
			},
			{
			 "value": 5,
			 "text": "Strongly agree"
			}
		       ]
		   },
		   {
		    "type": "radiogroup",
		    "name": "question24",
		    "title": "Recreational spaces I visit have good facilities and amenities",
		    "isRequired": true,
		    "choices": [
			{
			 "value": 1,
			 "text": "Strongly disagree"
			},
			{
			 "value": 2,
			 "text": "Disagree"
			},
			{
			 "value": 3,
			 "text": "Neutral"
			},
			{
			 "value": 4,
			 "text": "Agree"
			},
			{
			 "value": 5,
			 "text": "Strongly agree"
			}
		       ]
		   },
   		{
   		   "type": "radiogroup",
   		   "name": "question25",
   		   "title": "Recreational spaces I visit have organised activities (religious, cultural, yoga, plantation and other such activities).",
   		   "isRequired": true,
   		   "choices": [ {"value":1,"text":"Yes"},{"value":2,"text":"No"} ],
   		   "colCount": 0
   		 },
   		 {
   		   "type": "radiogroup",
   		   "name": "question26",
   		   "title": "Do you participate in those activities ?",
   		   "visibleIf": "{question25} = 1 ",
   		   "isRequired": true,
   		   "choices": [ {"value":1,"text":"Yes"},{"value":2,"text":"No"} ],
   		   "colCount": 0
   		 },
                 {
		    "type": "radiogroup",
		    "name": "question27",
		    "title": "Recreational spaces I visit are thermally comfortable",
		    "isRequired": true,
		    "choices": [
			{
			 "value": 1,
			 "text": "Strongly disagree"
			},
			{
			 "value": 2,
			 "text": "Disagree"
			},
			{
			 "value": 3,
			 "text": "Neutral"
			},
			{
			 "value": 4,
			 "text": "Agree"
			},
			{
			 "value": 5,
			 "text": "Strongly agree"
			}
		       ]
		   },
		   {
		    "type": "radiogroup",
		    "name": "question28",
		    "title": "My relationship to nature is an important part of who I am.",
		    "isRequired": true,
		    "choices": [
		     {
		      "value": 1,
		      "text": "Strongly disagree"
		     },
		     {
		      "value": 2,
		      "text": "Disagree"
		     },
		     {
		      "value": 3,
		      "text": "Neutral"
		     },
		     {
		      "value": 4,
		      "text": "Agree"
		     },
		     {
		      "value": 5,
		      "text": "Strongly agree"
		     }
		    ]
		   },
		   {
		    "type": "radiogroup",
		    "name": "question29",
		    "title": "I always think about how my actions affect the environment.",
		    "isRequired": true,
		    "choices": [
		     {
		      "value": 1,
		      "text": "Strongly disagree"
		     },
		     {
		      "value": 2,
		      "text": "Disagree"
		     },
		     {
		      "value": 3,
		      "text": "Neutral"
		     },
		     {
		      "value": 4,
		      "text": "Agree"
		     },
		     {
		      "value": 5,
		      "text": "Strongly agree"
		     }
		    ]
		   },
		   {
		    "type": "radiogroup",
		    "name": "question30",
		    "title": "I take notice of wildlife wherever I am.",
		    "isRequired": true,
		    "choices": [
		     {
		      "value": 1,
		      "text": "Strongly disagree"
		     },
		     {
		      "value": 2,
		      "text": "Disagree"
		     },
		     {
		      "value": 3,
		      "text": "Neutral"
		     },
		     {
		      "value": 4,
		      "text": "Agree"
		     },
		     {
		      "value": 5,
		      "text": "Strongly agree"
		     }
		    ]
		   },
		   {
		    "type": "radiogroup",
		    "name": "question31",
		    "title": "I feel very connected to all living things and the earth.",
		    "isRequired": true,
		    "choices": [
		     {
		      "value": 1,
		      "text": "Strongly disagree"
		     },
		     {
		      "value": 2,
		      "text": "Disagree"
		     },
		     {
		      "value": 3,
		      "text": "Neutral"
		     },
		     {
		      "value": 4,
		      "text": "Agree"
		     },
		     {
		      "value": 5,
		      "text": "Strongly agree"
		     }
		    ]
		   },
		   {
		    "type": "radiogroup",
		    "name": "question32",
		    "title": "My ideal vacation spot would be a remote, wilderness area.",
		    "isRequired": true,
		    "choices": [
		     {
		      "value": 1,
		      "text": "Strongly disagree"
		     },
		     {
		      "value": 2,
		      "text": "Disagree"
		     },
		     {
		      "value": 3,
		      "text": "Neutral"
		     },
		     {
		      "value": 4,
		      "text": "Agree"
		     },
		     {
		      "value": 5,
		      "text": "Strongly agree"
		     }
		    ]
		   },
		   {
		    "type": "radiogroup",
		    "name": "question33",
		    "title": "How much time per day do you typically use internet after school?",
		    "isRequired": true,
		    "choices": [
		     {
		      "value": 1,
		      "text": "None"
		     },
		     {
		      "value": 2,
		      "text": "11–30 min a day"
		     },
		     {
		      "value": 3,
		      "text": "31–60 min a day"
		     },
		     {
		      "value": 4,
		      "text": "one or two hours a day"
		     },
		     {
		      "value": 5,
		      "text": "more than two hours a day"
		     }
		    ]
		   },
		   {
		    "type": "radiogroup",
		    "name": "question34",
		    "title": "How much time per day do you typically use personal computers after school?",
		    "isRequired": true,
		    "choices": [
		     {
		      "value": 1,
		      "text": "None"
		     },
		     {
		      "value": 2,
		      "text": "11–30 min a day"
		     },
		     {
		      "value": 3,
		      "text": "31–60 min a day"
		     },
		     {
		      "value": 4,
		      "text": "one or two hours a day"
		     },
		     {
		      "value": 5,
		      "text": "more than two hours a day"
		     }
		    ]
		   },
		   {
		    "type": "radiogroup",
		    "name": "question35",
		    "title": "How much time each day do you typically use smartphones after school?",
		    "isRequired": true,
		    "choices": [
		     {
		      "value": 1,
		      "text": "None"
		     },
		     {
		      "value": 2,
		      "text": "11–30 min a day"
		     },
		     {
		      "value": 3,
		      "text": "31–60 min a day"
		     },
		     {
		      "value": 4,
		      "text": "one or two hours a day"
		     },
		     {
		      "value": 5,
		      "text": "more than two hours a day"
		     }
		    ]
		   },
		   {
		    "type": "radiogroup",
		    "name": "question36",
		    "title": "How much time each day do you typically watch TV after school?",
		    "isRequired": true,
		    "choices": [
		     {
		      "value": 1,
		      "text": "None"
		     },
		     {
		      "value": 2,
		      "text": "11–30 min a day"
		     },
		     {
		      "value": 3,
		      "text": "31–60 min a day"
		     },
		     {
		      "value": 4,
		      "text": "one or two hours a day"
		     },
		     {
		      "value": 5,
		      "text": "more than two hours a day"
		     }
		    ]
		   },
		   {
		    "type": "radiogroup",
		    "name": "question37",
		    "title": "How much time each day do you typically play video games?",
		    "isRequired": true,
		    "choices": [
		     {
		      "value": 1,
		      "text": "None"
		     },
		     {
		      "value": 2,
		      "text": "11–30 min a day"
		     },
		     {
		      "value": 3,
		      "text": "31–60 min a day"
		     },
		     {
		      "value": 4,
		      "text": "one or two hours a day"
		     },
		     {
		      "value": 5,
		      "text": "more than two hours a day"
		     }
		    ]
		   },
		   {
		    "type": "radiogroup",
		    "name": "question38",
		    "title": "How frequently per week do you engage in after-school private lessons or enrichment programmes like academic training, music courses, community sports, foreign languages etc.?",
		    "isRequired": true,
		    "choices": [
		     {
		      "value": 1,
		      "text": "None"
		     },
		     {
		      "value": 2,
		      "text": "Once a week"
		     },
		     {
		      "value": 3,
		      "text": "Two or three times a week"
		     },
		     {
		      "value": 4,
		      "text": "Four or five times a week"
		     },
		     {
		      "value": 5,
		      "text": "Almost every day"
		     }
		    ]
		   },
		   {
		    "type": "radiogroup",
		    "name": "question39",
		    "title": "Are there any adults in your family (such as sibling, parents or grandparents) who often tells you how fun and interesting it is to explore Recreational open spaces",
		    "isRequired": true,
		    "choices": [
		     {
		      "value": 1,
		      "text": "nobody"
		     },
		     {
		      "value": 2,
		      "text": "One person"
		     },
		     {
		      "value": 3,
		      "text": "More than one person"
		     }
		    ]
		   },
		   {
		    "type": "radiogroup",
		    "name": "question40",
		    "title": "Are there any adults in your family (such as sibling, parents or grandparents) who often prevents you from visiting Recreational open spaces?",
		    "isRequired": true,
		    "choices": [
		     {
		      "value": 1,
		      "text": "nobody"
		     },
		     {
		      "value": 2,
		      "text": "One person"
		     },
		     {
		      "value": 3,
		      "text": "More than one person"
		     }
		    ]
		   },
		   {
			"type": "radiogroup",
			"name": "question41",
			"title": "State your physical activity level ",
			"isRequired": true,
			"choices": [
			 {
			  "value": 1,
			  "text": "Low (Very slow walking/ strolling, resting/ sitting, standing activity, yoga/ stretching)"
			 },
			 {
			  "value": 2,
			  "text": "Moderate (Moderate-paced walking, Fast-paced walking, Cycling slowly, moderate intensity sport, using playground/ fitness equipment)"
			 },
			 {
			  "value": 3,
			  "text": "High (jogging or running, Cycling briskly, high intensity sport, boot camp / fitness program)"
			 }
			]
		       },
		{
     "type": "matrix",
     "name": "question42",
     "title": "Motivation for physical activity",
     "isRequired": true,
     "columns": [
      {
       "value": 0,
       "text": "No"
      },
      {
       "value": 1,
       "text": "Yes"
      }
     ],
     "rows": [
      {
       "value": "Row1",
       "text": "Access to physical activity equipment in ROS"
      },
      {
       "value": "Row2",
       "text": "I see many people engaging in physical activity in ROS ( walking, jogging, cycling, orplaying sports and active games)"
      }
     ]
    },
    {
     "type": "matrix",
     "name": "question43",
     "title": "Physical Health",
     "columns": [
      {
       "value": 0,
       "text": "No"
      },
      {
       "value": 1,
       "text": "Yes"
      }
     ],
     "rows": [
      {
       "value": "Row1",
       "text": "Diabetes"
      },
      {
       "value": "Row2",
       "text": "Blood Pressure"
      },
      {
       "value": "Row3",
       "text": "Asthama"
      }
     ],
      "isAllRowRequired": true
    },
	   {
	    "type": "text",
	    "name": "question44",
	    "title": "Weight (in kg)",
	    "isRequired": true
	   },
	   {
	    "type": "text",
	    "name": "question45",
	    "title": "Height (in cms)",
	    "isRequired": true
	   },
    {
     "type": "matrix",
     "name": "question46",
     "title": "Mental Health (Please select the answer that best describes your experience of each over the last 2 weeks) ",
     "columns": [
      {
       "value": 1,
       "text": "None of the time"
      },
      {
       "value": 2,
       "text": "Rarely"
      },
      {
       "value": 3,
       "text": "Some of the time"
      },
      {
       "value": 4,
       "text": "Often"
      },
      {
       "value": 5,
       "text": "All the time"
      }
     ],
     "rows": [
      {
       "value": "Row1",
       "text": "I have been feeling optimistic about the future"
      },
      {
       "value": "Row2",
       "text": "I have been feeling useful"
      },
      {
       "value": "Row3",
       "text": "I have been feeling relaxed"
      },
      {
       "value": "Row4",
       "text": "I have been dealing with problems well"
      },
      {
       "value": "Row5",
       "text": "I have been thinking clearly"
      },
      {
       "value": "Row6",
       "text": "I have been feeling close to other people"
      },
      {
       "value": "Row7",
       "text": "I have been able to make up my own mind about things"
      }
     ],
     "isAllRowRequired": true
    }
		  ]
		 }
		 
		]
	       };