import React,{useState,useCallback} from 'react'; 
import MySurvey from '../surveyTypes/surveytypeone'; 
import MySurvey2 from '../surveyTypes/surveytypetwo'; 
import Map from '../../Map';
// import {json} from '../surveys/questionone' ; 
import {db} from '../../firebase-config' ; 
// import {collection,getDocs,addDoc,setDoc,updateDoc,doc,deleteDoc} from "firebase/firestore" ; 
import {collection,addDoc} from "firebase/firestore" ; 


const  SurveyOne=({hindi,onlyMap})=>{
	const [showPage,setShowPage]=useState(true);
	const [showPage2,setShowPage2]=useState(true);
	const [showMap,setShowMap]=useState(true);
	const [data,setData]=useState(null) ; 

	const usersCollectionRef=collection(db,"survey"); 
	const createUser= async (data)=>{
	// console.log(data) ; 
	await addDoc(usersCollectionRef,data)
	// .then(docRef=>{console.log("document id : "+docRef.id)})
	// .catch(error=>{console.log(error);});     
		}

	const onCompletePage=useCallback((data)=>{
		setShowPage(!showPage) ; 
		setData(data) ; 
		// console.log("1st",showPage,showMap,showPage2) ;

	},[showPage])

	const onCompletePage2=useCallback((data)=>{
		setShowPage2(!showPage2) ; 
		setData(data) ; 
		createUser(data) ; 
		// console.log("2nd",showPage,showMap,showPage2) ;
	},[showPage2])


	const onCompleteMap=useCallback((data)=>{
		// console.log(data) ; 
		setData(data) ; 
		setShowMap(!showMap) ;
		if (onlyMap)
		   { setShowPage2(false); 
		     createUser(data) ; }
		// console.log("3nd",showPage,showMap,showPage2) ;
	},[showMap])

	const onClickHandler=()=>{
		setShowPage(true) ; 
		setShowPage2(true) ; 
		setShowMap(true) ; 
		setData(null) ; 
	}
	 const setFinalPage=useCallback((data)=>{
		return(
			<div className='card'>
			   <div className='container'>
				<h4>Thank you for taking the survey </h4>
				{/* <p >JSON DATA: {JSON.stringify(data,null,2)}</p> */}
				<button style={{color:'rgb(255,255,255)',
                    				appearance:'none',
                    				border:'none',
                    				fontFamily:'inherit',
                    				fontWeight:'bold',
                    				outline:'none',
                    				backgroundColor:'rgb(26,179,148',
                    				borderRadius:'1.214em',
                    				cursor:'pointer',
                    				fontSize:'1.1em',
                    				padding:'0.5em 2.786em 0.6em',
                    				textAlign:'left'}}
		    
		    onClick={onClickHandler}>Start New Survey</button>
				</div>
			</div>
		)
	 },[])
         const mapDivStyle={
		display: 'flex',
  		flexDiretion:'column',
  		alignItems: 'center' , 
  		alignContent: 'center',
  		justifyContent: 'center',
  		fontSize: 'calc(10px + 2vmin)',
  		boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
  		transition: '0.3s',
  		width: '40%',
  		borderRadius: '5px',
	 }
	 return (
		<div>
		{
		    showPage?   
		<MySurvey showCompletedPage={data=>onCompletePage(data)} hindi={hindi}  onlyMap={onlyMap}/> 
		: (showMap?<div ><Map showCompletedPage={data=>onCompleteMap(data)} data={data} hindi={hindi}/></div>
		: (showPage2?<div className='wid'><MySurvey2 showCompletedPage={data=>onCompletePage2(data) } data={data} hindi={hindi}/></div>
		 :setFinalPage(data)))
		
		    }
	
		</div>
	 )

}

export default SurveyOne ; 