import React,{useState,useCallback} from 'react'; 
import MySurvey from '../surveyTypes/mapsurveytypeone'; 
const  SurveyOne=({setMarkers,marker,setOpenModal,hindi})=>{
	// console.log(marker) ; 

	const [showPage,setShowPage]=useState(true);
	const onCompletePage=useCallback((data)=>{
		// console.log(data);
		setShowPage(!showPage) ; 
		setOpenModal(false) ; 

	},[showPage])
	 const setFinalPage=()=>{
		setOpenModal(false) ; 
	 }
	 return (
		<div>
		<MySurvey showCompletedPage={data=>onCompletePage(data)} setMarkers={setMarkers} marker={marker} hindi={hindi}/> 
		</div>
	 )
}

export default SurveyOne ; 