import {React,useState,createContext} from 'react'; 
import MySurvey  from './surveyDisplay/surveyone' ;  



export const LanguageContext=createContext(); 

function HomePage(  ){
      const [openHomePage,setOpenHomePage]=useState(true) ; 

      const [hindi,setHindi]=useState(false) ; 
      const [onlyMap,setOnlyMap]=useState(false) ; 

      const buttonClickHandler=()=>{
	  setOpenHomePage(false) ; 
      }
  

      const buttonSetHindi=()=>{ setHindi(!hindi) ; }
     const buttonSetOnlyMap=()=>
      {setOnlyMap(!onlyMap);
	setOpenHomePage(false) ;
	}
    return (openHomePage? hindi?
      
	<div className="homepage">
	<div id="homepageHeader" >
	<h4>मौलाना आज़ाद राष्ट्रीय प्रद्योगिकी संस्थान, भोपाल</h4>
	<img id='logo' onClick={buttonSetOnlyMap} src="/manitLogo.png" />
	<button className="language" onClick={buttonSetHindi}>English</button>
	</div>
	<div id="homepageBody" >
		<p>यह शोध आर्किटेक्चर एंड प्लानिंग डिपार्टमेंट, मैनिट द्वारा किया जा रहा है। यह शोध किशोरों के मनोरंजक  खुले स्थान (जैसे पार्क, मैदान, खेल का मैदान, खेल परिसर, 
		और कोई अन्य मनोरंजक खुली जगह) के अवसरों और उनके स्वास्थ्य के साथ सम्बन्ध की जांच करने के लिए किया जा रहा है। यह सर्वे किशोर आयु
		 वर्ग के लिए है। सर्वे के परिणाम स्वस्थ और न्यायसंगत दृष्टिकोण से आरओएस के प्रावधान के लिए शहरी नियोजन तैयार करने में मदद करेंगे।
		  इस सर्वे के उत्तरदाताओं को गुमनाम रखा जाएगा और उनकी जवाबों को गोपनीय रखा जाएगा। इस सर्वे को पूरा होने में लगभग 15 मिनट लगेंगे। 
		  हम आपके समर्थन और सहयोग के अत्यधिक आभारी रहेंगे।

</p>
{/*<h4>निर्देश</h4>
	    <ul id='निर्देश'>
                <li style={{paddingBottom:'.5em'}}>मैप आधारित सर्वे भरने के लिए, कृपया पहले अपना घर या निकटतम सड़क जंक्शन मार्क करें।</li>
                <li style={{paddingBottom:'.5em'}}>उसके बाद आप जिन पार्क, मैदान, खेल का मैदान, खेल परिसर, और कोई अन्य मनोरंजक खुली 
		जगह पर अक्सर अपने दोस्तों के साथ जाते हैं और न की बड़ों के साथ, ऐसे 5 जगह तक मार्क कर सकते हैं। </li>
                <li style={{paddingBottom:'.5em'}}>हर पार्क, मैदान, खेल का मैदान, खेल परिसर, और कोई अन्य मनोरंजक खुली जगह का 
		विवरण भरने के लिए प्रत्येक मार्कर पर क्लिक करें।</li>
		<li style={{paddingBottom:'.5em'}}>ध्यान रहे की मैप पर स्थान मार्क करने के लिए ज़ूम स्तर 17 या अधिक होना चाहिए।</li>

           </ul> */}
		
	</div>
	<div id="homepageFooter" >
	<button className="customButton sdn-btn" onClick={buttonClickHandler}  >सर्वे शुरू करें </button></div>
	<LanguageContext.Provider value={hindi}>
	</LanguageContext.Provider>
	</div>

	:

	<div className="homepage">
	<div id="homepageHeader" >
	<h4>Maulana Azad National Institute of Technology</h4>
	<img id='logo'  onClick={buttonSetOnlyMap} src="/manitLogo.png" />
	<button className="language" onClick={buttonSetHindi}>हिंदी</button>
	</div>
	<div id="homepageBody" >
		<p>This research is being done by Architecture and Planning Department, MANIT. This study is undertaken to assess 
		Recreational Open Space (ROS: Parks,playfields,sports complex and other such open spaces) opportunities to adolescents and its association to their health. This survey is targeted for 
		adolescent age group. Results of survey would help formulate urban planning guidelines for provision
		 of ROS at neighborhood level from healthy and equitable perspective. The respondents of this survey
		  shall be anonymous and their responses will be kept confidential. This survey will take approximately 
		  15 mins to complete.

</p>
{/* <h4>Instructions</h4>
	   <ul id='instructions'>
                <li style={{paddingBottom:'.5em'}}>For map based survey, please mark your home location or nearest road junction.</li>
                <li style={{paddingBottom:'.5em'}}>You may mark upto 5 ROS on map you visit most frequently. Click on each marker to fill the details</li>
                <li style={{paddingBottom:'.5em'}}>For marking location on map, zoom level should be 17 or more.</li>
           </ul> */}
		
	</div>
	<div id="homepageFooter" >
	<button className="customButton sdn-btn" onClick={buttonClickHandler}  >Start Survey</button></div>
	
	</div>
  
      :<MySurvey hindi={hindi} onlyMap={onlyMap}/>

 

    )
}

export default HomePage; 