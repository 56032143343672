export const jsonHindi={
	// "title":" ",
	// "description":"Accessibility of open spaces  " ,
	// "logoPosition": "right",
	// "logo":"/manitLogo.png",
	// "logoWidth":60,
	// "logoHeight":60,
	// "showQuestionNumbers":"off",
	"pages": [
	 {
	  "name": "page1",
	  "elements": [
	   {
	    "type": "radiogroup",
	    "name": "question1",
	    "title": "कक्षा",
	    "isRequired": true,
	    "choices": [
	     {
	      "value": 1,
	      "text": "9"
	     },
	     {
	      "value": 2,
	      "text": "10"
	     },
	     {
	      "value": 3,
	      "text": "11"
	     },
	     {
	      "value": 4,
	      "text": "12"
	     },

	    ]
	   },
	   {
	    "type": "radiogroup",
	    "name": "question2",
	    "title": "लिंग",
	    "isRequired": true,
	    "choices": [
	     {
	      "value": 2,
	      "text": "स्त्री"
	     },
	     {
	      "value": 1,
	      "text": "पुरुष"
	     }
	    ]
	   },
	   {
	    "type": "radiogroup",
	    "name": "question3",
	    "title": "परिवार का प्रकार",
	    "isRequired": true,
	    "choices": [
	     {
	      "value": 1,
	      "text": "एकल परिवार"
	     },
	     {
	      "value": 2,
	      "text": "संयुक्त परिवार"
	     },
	//      {
	//       "value": 3,
	//       "text": "विस्तृत परिवार"
	//      },
	    ]
	   },
	   {
	    "type": "text",
	    "name": "question4",
	    "title": "भाई बहनों की संख्या (आपको को मिला के )",
	    "isRequired": true
	   },
	   {
	    "type": "radiogroup",
	    "name": "question5",
	    "title": "निवास का स्वामित्व",
	    "choices": [
	     {
	      "value": 1,
	      "text": "खुद का"
	     },
	     {
	      "value": 2,
	      "text": "किराये का"
	     }
	    ]
	   },
	   {
	    "type": "radiogroup",
	    "name": "question6",
	    "title": "आप कितने समय से अपने घर पर रह रहे हैं?",
	    "isRequired": true,
	    "choices": [
	     {
	      "value": 1,
	      "text": "0-5 साल"
	     },
	     {
	      "value": 2,
	      "text": "5-10 साल"
	     },
	     {
	      "value": 3,
	      "text": "10 साल से ज्यादा"
	     }
	    ]
	   },
	   {
	    "type": "radiogroup",
	    "name": "question7",
	    "title": "माता की शिक्षा",
	    "isRequired": true,
	    "choices": [
	     {
	      "value": 1,
	      "text": "हाई स्कूल"
	     },
	     {
	      "value": 2,
	      "text": "डिप्लोमा/सर्टिफिकेट"
	     },
	     {
	      "value": 3,
	      "text": "अंडर ग्रेजुएट"
	     },
	     {
	      "value": 4,
	      "text": "पोस्ट ग्रेजुएट"
	     },
	     {
	      "value": 5,
	      "text": "कोई पढ़ाई नही"
	     }
	    ]
	   },
	   {
	    "type": "radiogroup",
	    "name": "question8",
	    "title": "क्या आपके परिवार के पास कार या अन्य मोटर चालित वाहन है? ",
	    "isRequired": true,
	    "choices": [
	     {
	      "value": 1,
	      "text": "नहीं है"
	     },
	     {
	      "value": 2,
	      "text": "एक"
	     },
	     {
	      "value": 3,
	      "text": "दो"
	     },
	     {
	      "value": 4,
	      "text": "दो से अधिक"
	     }
	    ]
	   },
	   {
	    "type": "radiogroup",
	    "name": "question9",
	    "title": "क्या आपका अपना बेडरूम है? ",
	    "isRequired": true,
	    "choices": [
	     {
	      "value": 1,
	      "text": "नहीं"
	     },
	     {
	      "value": 2,
	      "text": "हाँ"
	     }
	    ]
	   },
	   {
	    "type": "radiogroup",
	    "name": "question10",
	    "title": "आपके परिवार के पास कितने कंप्यूटर (लैपटॉप और टैबलेट सहित, स्मार्ट फोन और गेम कंसोल शामिल नहीं हैं) हैं?",
	    "isRequired": true,
	    "choices": [
	     {
	      "value": 1,
	      "text": "नहीं है"
	     },
	     {
	      "value": 2,
	      "text": "एक"
	     },
	     {
	      "value": 3,
	      "text": "दो"
	     },
	     {
	      "value": 4,
	      "text": "दो से अधिक"
	     }
	    ]
	   },
	   {
	    "type": "radiogroup",
	    "name": "question11",
	    "title": "आपके घर में कितने बाथरूम हैं?",
	    "isRequired": true,
	    "choices": [
	     {
	      "value": 1,
	      "text": "नहीं है"
	     },
	     {
	      "value": 2,
	      "text": "एक"
	     },
	     {
	      "value": 3,
	      "text": "दो"
	     },
	     {
	      "value": 4,
	      "text": "दो से अधिक"
	     }
	    ]
	   },
	   {
	    "type": "radiogroup",
	    "name": "question12",
	    "title": "पिछले 12 महीनों के दौरान आप अपने परिवार के साथ कितनी बार छुट्टी/अवकाश पर भोपाल के बहार घूमने गए? ",
	    "isRequired": true,
	    "choices": [
	     {
	      "value": 1,
	      "text": "कभी नहीँ"
	     },
	     {
	      "value": 2,
	      "text": "एक बार"
	     },
	     {
	      "value": 3,
	      "text": "दो बार"
	     },
	     {
	      "value": 4,
	      "text": "दो बार से अधिक"
	     }
	    ]
	   },
	   {
	    "type": "radiogroup",
	    "name": "question13",
	    "title": "अपने लिए मासिक खर्च का पैसा (पॉकेट मनी और कमाया हुआ पैसा शामिल है)",
	    "isRequired": true,
	    "choices": [
	     {
	      "value": 1,
	      "text": "नहीं है"
	     },
	     {
	      "value": 2,
	      "text": "500 रुपये से कम"
	     },
	     {
	      "value": 3,
	      "text": "500-1000 रुपये"
	     },
	     {
	      "value": 4,
	      "text": "1000 रुपये से अधिक"
	     },
	    ]
	   }, 
	   {
		"type": "radiogroup",
		"name": "question14",
		"title": "क्या आपके घर के पास कोई पार्क, मैदान, खेल का मैदान या खेल परिसर या ऐसी कोई अन्य खुली जगह है, जहाँ आप बड़ों के बिना स्वतंत्र रूप से जाते हैं। ",
		"isRequired": true,
		"choices": [
		 {
		  "value": 1,
		  "text": "नहीं"
		 },
		 {
		  "value": 2,
		  "text": "हाँ"
		 }
		]
	       }
	] 
	 } 
	  
	] 
       };