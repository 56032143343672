import {useState, useCallback } from 'react';

// Modern theme
// import 'survey-core/modern.min.css';
// Default V2 theme
import 'survey-core/defaultV2.min.css';
import { StylesManager, Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import {json} from '../surveys/questionone' ; 
import {jsonHindi} from '../surveys/questiononeHindi' ; 
import {jsonMap} from '../surveys/questiononeMap' ; 

// const SURVEY_ID = 1;
// StylesManager.applyTheme("modern");
StylesManager.applyTheme("defaultV2");


const MySurvey=(prop)=>{


	const survey=new Model(prop.onlyMap?jsonMap:(prop.hindi?jsonHindi:json)) ; 

	// if (prop.hindi){
	// 	const survey = new Model(jsonHindi);
        //   	}
	// else { const survey=new Model(json); }	  

	survey.showCompletedPage=false ; 
	survey.completeText=(prop.hindi?"अगला":"Next") ; 
        //  survey.data={
	// 	"question1":1,// grade
	// 	"question2":2,// Gender 
	// 	"question3":1,// Family type
	// 	"question4":2,// No of siblings
	// 	"question5":1,// Ownership of residence
	// 	"question6":2,// How long have you been living at your home ? 
	// 	"question7":3,// Education of mother 
	// 	"question8":4,// Does your family own a car or another motorized  vehicle ?
	// 	"question9":1,// Do you have your own bedroom
	// 	"question10":1,//How many computers (including laptops and tablets, not including smart phones and game consoles) does your family own?
	// 	"question11":2,//How many bathrooms (room with a bath/shower or both) are there in your home? 
	// 	"question12":1,//How many times did you with your family travel for holiday/vacation during the past 12 months? 
	// 	"question13":2,//Monthly spending money (MONEY) for yourself(comprising pocket money and money earned)
	// 	"question14":2//Do you have any ROS (Park, Maidan, Playfield or sports complex or any other such open spaces) where you visit independently without adult supervision?
	//  } 
	const onCompletePage = useCallback((sender) => {
		prop.showCompletedPage(sender.data) ; 
	}, []);
      
	survey.onComplete.add(onCompletePage);
	return <Survey model={survey} />
      }
      export default MySurvey;
      

