import React,{useState} from 'react' ; 
import MySurvey  from './Components/surveyDisplay/surveyone' ;  
// import MySurvey  from './components/surveyDisplay/surveytwo' ;  
import HomePage from './Components/homepage' ; 
import HomePageHindi from './Components/homepagehindi' ; 

import './App.css';
// import {BrowserRouter as Router,Switch, Route,Link} from 'react-router-dom' ; 

function App() {

  
  return (
    <div className="App">
      <main>
     {/* {openHomePage?<HomePage setOpenHomePage={setOpenHomePage} />:<MySurvey/>} */}
     {<HomePage />}
      </main>
    </div>
  );
}

export default App;
