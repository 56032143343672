export const json={
	// "title":" ",
	// "description":"Accessibility of open spaces  " ,
	// "logoPosition": "right",
	// "logo":"/manitLogo.png",
	// "logoWidth":60,
	// "logoHeight":60,
	// "showQuestionNumbers":"off",
	"pages": [
	 {
	  "name": "page1",
	  "elements": [
	   {
	    "type": "radiogroup",
	    "name": "question1",
	    "title": "Grade",
	    "isRequired": true,
	    "choices": [
	     {
	      "value": 1,
	      "text": "9"
	     },
	     {
	      "value": 2,
	      "text": "10"
	     },
	     {
	      "value": 3,
	      "text": "11"
	     },
	     {
	      "value": 4,
	      "text": "12"
	     },

	    ]
	   },
	   {
	    "type": "radiogroup",
	    "name": "question2",
	    "title": "Gender",
	    "isRequired": true,
	    "choices": [
	     {
	      "value": 2,
	      "text": "Female"
	     },
	     {
	      "value": 1,
	      "text": "Male"
	     }
	    ]
	   },
	   {
	    "type": "radiogroup",
	    "name": "question3",
	    "title": "Family type",
	    "isRequired": true,
	    "choices": [
	     {
	      "value": 1,
	      "text": "Nuclear family"
	     },
	     {
	      "value": 2,
	      "text": "Joint family"
	     },
	//      {
	//       "value": 3,
	//       "text": "Extended family"
	//      },
	    ]
	   },
	   {
	    "type": "text",
	    "name": "question4",
	    "title": "No. of siblings(including you)",
	    "isRequired": true
	   },
	   {
	    "type": "radiogroup",
	    "name": "question5",
	    "title": "Ownership of residence",
	    "choices": [
	     {
	      "value": 1,
	      "text": "Owned"
	     },
	     {
	      "value": 2,
	      "text": "Rented"
	     }
	    ]
	   },
	   {
	    "type": "radiogroup",
	    "name": "question6",
	    "title": "How long have you been living at your home ?",
	    "isRequired": true,
	    "choices": [
	     {
	      "value": 1,
	      "text": "0-5 years"
	     },
	     {
	      "value": 2,
	      "text": "5-10 years"
	     },
	     {
	      "value": 3,
	      "text": "More than 10 years"
	     }
	    ]
	   },
	   {
	    "type": "radiogroup",
	    "name": "question7",
	    "title": "Education of mother",
	    "isRequired": true,
	    "choices": [
	     {
	      "value": 1,
	      "text": "High school"
	     },
	     {
	      "value": 2,
	      "text": "Diploma/Certificate"
	     },
	     {
	      "value": 3,
	      "text": "Under graduate"
	     },
	     {
	      "value": 4,
	      "text": "Post Graduate"
	     },
	     {
	      "value": 5,
	      "text": "No education"
	     }
	    ]
	   },
	   {
	    "type": "radiogroup",
	    "name": "question8",
	    "title": "Does your family own a car or another motorized vehicle? ",
	    "isRequired": true,
	    "choices": [
	     {
	      "value": 1,
	      "text": "None"
	     },
	     {
	      "value": 2,
	      "text": "One"
	     },
	     {
	      "value": 3,
	      "text": "Two"
	     },
	     {
	      "value": 4,
	      "text": "More than two"
	     }
	    ]
	   },
	   {
	    "type": "radiogroup",
	    "name": "question9",
	    "title": "Do you have your own bedroom? ",
	    "isRequired": true,
	    "choices": [
	     {
	      "value": 1,
	      "text": "No"
	     },
	     {
	      "value": 2,
	      "text": "Yes"
	     }
	    ]
	   },
	   {
	    "type": "radiogroup",
	    "name": "question10",
	    "title": "How many computers (including laptops and tablets, not including smart phones and game consoles) does your family own?",
	    "isRequired": true,
	    "choices": [
	     {
	      "value": 1,
	      "text": "None"
	     },
	     {
	      "value": 2,
	      "text": "One"
	     },
	     {
	      "value": 3,
	      "text": "Two"
	     },
	     {
	      "value": 4,
	      "text": "More than two"
	     }
	    ]
	   },
	   {
	    "type": "radiogroup",
	    "name": "question11",
	    "title": "How many bathrooms (room with a bath/shower or both) are there in your home? ",
	    "isRequired": true,
	    "choices": [
	     {
	      "value": 1,
	      "text": "None"
	     },
	     {
	      "value": 2,
	      "text": "One"
	     },
	     {
	      "value": 3,
	      "text": "Two"
	     },
	     {
	      "value": 4,
	      "text": "More than two"
	     }
	    ]
	   },
	   {
	    "type": "radiogroup",
	    "name": "question12",
	    "title": "How many times did you with your family travel for holiday/vacation during the past 12 months? ",
	    "isRequired": true,
	    "choices": [
	     {
	      "value": 1,
	      "text": "Never"
	     },
	     {
	      "value": 2,
	      "text": "Once"
	     },
	     {
	      "value": 3,
	      "text": "Twice"
	     },
	     {
	      "value": 4,
	      "text": "More than twice"
	     }
	    ]
	   },
	   {
	    "type": "radiogroup",
	    "name": "question13",
	    "title": "Monthly spending money for yourself(comprising pocket money and money earned)",
	    "isRequired": true,
	    "choices": [
	     {
	      "value": 1,
	      "text": "None"
	     },
	     {
	      "value": 2,
	      "text": "Less than Rs 500"
	     },
	     {
	      "value": 3,
	      "text": "Rs 500-1000"
	     },
	     {
	      "value": 4,
	      "text": "More than Rs 1000"
	     },
	    ]
	   }, 
 	   {
	    "type": "radiogroup",
	    "name": "question14",
	    "title": "Do you have any ROS (Park, Maidan, Playfield or sports complex or any other such open spaces) where you visit independently without adult supervision?",
	    "isRequired": true,
	    "choices": [
	     {
	      "value": 1,
	      "text": "No"
	     },
	     {
	      "value": 2,
	      "text": "Yes"
	     }
	    ]
	   }
	   ] 
	 } 
	  
	] 
       };