export const jsonMap={
	// "title":" ",
	// "description":"Accessibility of open spaces  " ,
	// "logoPosition": "right",
	// "logo":"/manitLogo.png",
	// "logoWidth":60,
	// "logoHeight":60,
	// "showQuestionNumbers":"off",
	"pages": [
	 {
	  "name": "page1",
	  "elements": [
	   {
	    "type": "text",
	    "name": "question50",
	    "title": "Survey No",
	    "isRequired": true,
	    "maxlength":3,
	   },
	  {
	    "type": "radiogroup",
	    "name": "question14",
	    "title": "Do you have any ROS (Park, Maidan, Playfield or sports complex or any other such open spaces) where you visit independently without adult supervision?",
	    "isRequired": true,
	    "choices": [
	     {
	      "value": 1,
	      "text": "No"
	     },
	     {
	      "value": 2,
	      "text": "Yes"
	     }
	    ]
	   }
 
	] 
	 } 
	  
	] 
       };
