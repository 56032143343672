
import {useState, useCallback } from 'react';

// Modern theme
// import 'survey-core/modern.min.css';
// Default V2 theme
import 'survey-core/defaultV2.min.css';
import { StylesManager, Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import {json} from '../surveys/mapquestionone' ; 
import {jsonHindi} from '../surveys/mapquestiononeHindi'
import { Marker } from '@react-google-maps/api';
// const SURVEY_ID = 1;
// StylesManager.applyTheme("modern");
StylesManager.applyTheme("defaultV2");



function MySurvey(prop) {
	const survey = new Model(prop.hindi?jsonHindi:json);
	// console.log(prop.marker.question1);
	survey.showCompletedPage=false ; 
	survey.setValue('question1',prop.marker.question1) ; 
	survey.setValue('question2',prop.marker.question2) ; 
	survey.setValue('question3',prop.marker.question3) ; 
	survey.setValue('question4',prop.marker.question4) ; 
	survey.setValue('question5',prop.marker.question5) ; 
	survey.pageNextText = (prop.hindi?"अगला":"Next");
	survey.completeText = (prop.hindi?"पूर्ण करें":"Done");
        survey.pagePrevText=(prop.hindi?"पिछला":"Previous");
	survey.onValueChanged.add(function (sender, options) {
	    var mySurvey = sender;
	    var questionName = options.name;
	    var newValue = options.value;
	//    console.log(questionName);
	//    console.log(newValue);
	//     console.log(prop.marker) ; 
	    prop.setMarkers((current)=>{
        //       console.log(current) ; 
		current[prop.marker.pos][questionName]=options.value; 
		return current ;
	    }) 
	//     console.log(questionName,newValue) ; 
 	 });

	const onCompletePage = useCallback((sender) => {
	//   console.log(sender);
	  prop.showCompletedPage(sender.data) ; 
	  // saveSurveyResults(
	  //   "https://your-web-service.com/" + SURVEY_ID,
	  //   sender.data
	  // )
	}, []);
	
      
	survey.onComplete.add(onCompletePage);
      
	return <Survey model={survey} />
      }
      
export default MySurvey; 