export const jsonHindi={
	"logoPosition": "right",
	"pages": [
	 {
	  "name": "page1",
	  "elements": [
	   {
	    "type": "radiogroup",
	    "name": "question1",
	    "isRequired": "true",
	    "title": "आप कितनी बार इस स्थान पर जाते हैं?",
	    "choices": [
	     {
	      "value": 1,
	      "text": "कभी नहीँ"
	     },
	     {
	      "value": 2,
	      "text": "महीने में एक बार"
	     },
	     {
	      "value": 3,
	      "text": "महीने में दो या तीन बार"
	     },
	     {
	      "value": 4,
	      "text": "सप्ताह में एक या दो बार "
	     },
	     {
	      "value": 5,
	      "text": "सप्ताह में तीन या चार बार"
	     },
	     {
	      "value": 6,
	      "text": "लगभग हर दिन"
	     }
	    ]
	   }
	  ]
	 },
	 {
	  "name": "page2",
	  "elements": [
	   {
	    "type": "radiogroup",
	    "name": "question2",
	    "title": "रहने की अवधि",
	    "isRequired": "true",
	    "choices": [
	     {
	      "value": 1,
	      "text": "15 मिनट से कम"
	     },
	     {
	      "value": 2,
	      "text": "15-30 मिनट"
	     },
	     {
	      "value": 3,
	      "text": "30 मिनट - 1 घंटा"
	     },
	     {
	      "value": 4,
	      "text": "1-2 घंटे"
	     },
	     {
	      "value": 5,
	      "text": "2 घंटे से अधिक "
	     }
	    ]
	   }
	  ]
	 },
	 {
	  "name": "page3",
	  "elements": [
	   {
	    "type": "radiogroup",
	    "name": "question3",
	    "title": "आप इस स्थान पर कब जाना पसंद करते हैं?",
	    "isRequired": "true",
	    "choices": [
	     {
	      "value": 1,
	      "text": "सुबह"
	     },
	     {
	      "value": 2,
	      "text": "दोपहर"
	     },
	     {
	      "value": 3,
	      "text": "शाम को जल्दी"
	     },
	     {
	      "value": 4,
	      "text": "देर शाम (शाम 7 बजे के बाद)"
	     }
	    ]
	   }
	  ]
	 },
	 {
	  "name": "page4",
	  "elements": [
		{
		 "type": "checkbox",
		 "name": "question4",
		 "title": "इस स्थान पर जाने का उद्देश्य बताएं ",
		 "isRequired": true,
		 "choices": [
		  {
		   "value": 1,
		   "text": "पर्यावरण (प्रकृति का आनंद लेने, बाहर समय बिताने)"
		  },
		  {
		   "value": 2,
		   "text": "शारीरिक (व्यायाम/फिटनेस प्राप्त करने)"
		  },
		  {
		   "value": 3,
		   "text": "मनोवैज्ञानिक (तनाव से बचने, शांति का आनंद लेने, कुछ रचनात्मक करने, सोचने/चिंतन करने के लिए जगह, आराम करने/आराम करने के लिए जगह)"
		  },
		  {
		   "value": 4,
		   "text": "सामाजिक (दोस्तों के साथ समय बिताने, अच्छे लोगों के साथ रहने, परिवार से जुड़ने)"
		  }
		 ],
		 "hasSelectAll": true,
		 "maxSelectedChoices": 4
		}
	       ]
	 }
	]
       }