import { initializeApp } from "firebase/app";
import {getFirestore} from '@firebase/firestore' ; 

const firebaseConfig = {
	apiKey: "AIzaSyCCwOKjaW-D3BYlNFe-lYfc3ZM9LE-lo5g",
	authDomain: "surveynitbhopal.firebaseapp.com",
	databaseURL: "https://surveynitbhopal-default-rtdb.asia-southeast1.firebasedatabase.app",
	projectId: "surveynitbhopal",
	storageBucket: "surveynitbhopal.appspot.com",
	messagingSenderId: "1070463512747",
	appId: "1:1070463512747:web:0a30978ffcefa965b847ee",
	measurementId: "G-MMJJSPW7L9"
      };

const app=initializeApp(firebaseConfig) ; 
export const db=getFirestore(app);  