export const json={
	"logoPosition": "right",
	"pages": [
	 {
	  "name": "page1",
	  "elements": [
	   {
	    "type": "radiogroup",
	    "name": "question1",
	    "isRequired": "true",
	    "title": "How frequently you visit this location ?",
	    "choices": [
	     {
	      "value": 1,
	      "text": "Never"
	     },
	     {
	      "value": 2,
	      "text": "Once a month"
	     },
	     {
	      "value": 3,
	      "text": "Two or three times a month"
	     },
	     {
	      "value": 4,
	      "text": "One or two times a week "
	     },
	     {
	      "value": 5,
	      "text": "Three or four times a week"
	     },
	     {
	      "value": 6,
	      "text": "Almost every day"
	     }
	    ]
	   }
	  ]
	 },
	 {
	  "name": "page2",
	  "elements": [
	   {
	    "type": "radiogroup",
	    "name": "question2",
	    "title": "Duration of stay",
	    "isRequired": "true",
	    "choices": [
	     {
	      "value": 1,
	      "text": "Less than 15 min"
	     },
	     {
	      "value": 2,
	      "text": "15-30 min"
	     },
	     {
	      "value": 3,
	      "text": "30 min- 1h"
	     },
	     {
	      "value": 4,
	      "text": "1-2 h"
	     },
	     {
	      "value": 5,
	      "text": "More than 2 h "
	     }
	    ]
	   }
	  ]
	 },
	 {
	  "name": "page3",
	  "elements": [
	   {
	    "type": "radiogroup",
	    "name": "question3",
	    "title": "When do you prefer to visit this location",
	    "isRequired": "true",
	    "choices": [
	     {
	      "value": 1,
	      "text": "Morning"
	     },
	     {
	      "value": 2,
	      "text": "Afternoon"
	     },
	     {
	      "value": 3,
	      "text": "Early evening"
	     },
	     {
	      "value": 4,
	      "text": "Late evening (after 7 pm)"
	     }
	    ]
	   }
	  ]
	 },
	 {
	  "name": "page4",
	  "elements": [
		{
		 "type": "checkbox",
		 "name": "question4",
		 "title": "State the purpose of visit ",
		 "isRequired": true,
		 "choices": [
		  {
		   "value": 1,
		   "text": "Environmental (enjoy/ observe nature, spending time outside)"
		  },
		  {
		   "value": 2,
		   "text": "Physical (Get exercise/fitness)"
		  },
		  {
		   "value": 3,
		   "text": "Psychological (escape stress, enjoy calmness, do something creative, place to think/reflect, place to rest/relax)"
		  },
		  {
		   "value": 4,
		   "text": "Social (spend time with friends, be around good people, connect with family)"
		  }
		 ],
		 "hasSelectAll": true,
		 "maxSelectedChoices": 4
		}
	       ]
	 }
	]
       }