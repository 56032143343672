import React,{useState,useCallback,useRef,useEffect} from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
} from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
// import { formatRelative,format } from "date-fns";
import {format } from "date-fns";
import "@reach/combobox/styles.css";
import mapStyles from "./mapStyles";
// import { parseWithOptions } from "date-fns/fp";
import Modal from "./Components/Modal";

const libraries = ["places"];
const mapContainerStyle = {
  height: "80vh",
  width: "95vw",
  fontSize: 'calc(10px + 2vmin)',
  boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
  transition: '0.3s',
  borderRadius: '5px',
};
const options = {
  styles: mapStyles,
  // disableDefaultUI: true,
  zoomControl: true,
  streetViewControl: false,
  // zoomControlOptions : { position: window.google.maps.ControlPosition.LEFT_BOTTOM }  
};

const center = {
  lat: 23.259933,
	lng: 77.412613,
};

export default function Map({showCompletedPage,data,hindi}) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });
  const [markers, setMarkers] = useState([]);
  const [selected, setSelected] = useState(null);
  const [modalOpen, setModalOpen] = useState(true);
  const [questionText,setQuestionText]=useState(hindi?"कृपया पहले अपना घर या निकटतम सड़क जंक्शन मार्क करें।":"Mark your home or nearest intersection") ; 
  const [mapZoom,setMapZoom]=useState(14) ;
  const [info,setInfo]=useState(true) ; 
  
  useEffect(() => { window.scrollTo(0, 0) }, [])

  const onMapClick = useCallback((e) => {
    // console.log(mapZoom) ;
    if (mapZoom>=17){
    let quesText=(hindi?"अपने आस-पड़ोस के खुले स्थानों को मार्क करें जैसे पार्क, मैदान, खेल का मैदान, खेल परिसर, और कोई अन्य मनोरंजक खुली जगह, जहाँ आप अपने दोस्तों के साथ अक्सर जाते हैं और न की बड़ों के साथ।":
    "Mark neighborhood placesMark neighborhood places (Parks, Maidan , play field, sports complex, and any other recreational open space) that you visit the most independently");
  if (data.question14===2)
    {setQuestionText(quesText) ; }  
    setMarkers((x) =>{ 
      if (x.length<6) {
      return[
      ...x,
      {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
        time: format(new Date(),"dd-MM-yy hh:mm:ss"),
        pos: x.length,
        question1:null,question2:null,question3:null,question4:null,
      },];}
      else { return x ; }
    });
  }
  }
  , [mapZoom]);

  const mapRef = useRef();
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const panTo = useCallback(({ lat, lng }) => {
    setSelected(null) ; 
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(17);
  }, []);

  const onMapZoomChanged=useCallback(()=>{
    if (mapRef.current){ 
    setMapZoom(mapRef.current.getZoom()) ; 
    }
  },[])


  function onMarkerDragEnd(e, index) {
    setMarkers((x) => {
      x[index].lat = e.latLng.lat();
      x[index].lng = e.latLng.lng();
      return x;
    });
    // console.log(index);
  }
  function handleMarkerClick(e,marker){
    if (marker.pos!==0){
    setSelected(marker) ; 
    setModalOpen(true);
    }
  } 

  const infoButtonClickHandler=()=>{
    //  console.log(info); 
    setModalOpen(true) ; 
    setInfo(true) ; 

  }

  const mapCompleteHandler=()=>{
      
      if (markers.length===0) {
        setInfo(true) ; 
        setModalOpen(true) ; 
      }  
      else if (markers.length===1 && data.question14===2){
         alert("Please mark atleast 1 location where you visit for recreation")
      }
      else {
      let newData={...data,dt:markers[0].time,homeLat:markers[0].lat,homeLng:markers[0].lng}
      // console.log(newData);
      let missedMarkers="" ; 
      let firstMissedMarkerLat=null ; 
      let firstMissedMarkerLng=null ; 
      for(var i=1 ; i<markers.length;i++){
        // console.log(markers[i].pos);

        newData["m"+markers[i].pos+"Lat"] =markers[i].lat;
        newData["m"+markers[i].pos+"Lng"] =markers[i].lng;
        newData["m"+markers[i].pos+"q1"] =markers[i].question1;
        newData["m"+markers[i].pos+"q2"] =markers[i].question2;
        newData["m"+markers[i].pos+"q3"] =markers[i].question3;
        newData["m"+markers[i].pos+"q4"] =markers[i].question4;
        // newData["m"+markers[i].pos+"q5"] =markers[i].question5;
        
        let q="question"
        for(var j=1;j<=4;j++){
          let question=q+j; 
          if (!markers[i][question]) 
          {  if (firstMissedMarkerLat==null){firstMissedMarkerLat=markers[i].lat;firstMissedMarkerLng=markers[i].lng;}  
            missedMarkers+=markers[i].pos+", " ;
           break; }
        }

      }
        // console.log(newData);
        if (missedMarkers){
          missedMarkers ="Some questions are missed forMarker "+missedMarkers.replace(/,\s*$/, "");
          // console.log(missedMarkers);
          alert(missedMarkers); 
          panTo({lat:firstMissedMarkerLat,lng:firstMissedMarkerLng}) ; 
        }
        else{
          showCompletedPage(newData) ; 
        }
      }

  }

  // function deleteMarker(index){
      // setMarkers((x)=>{
        // return x.splice(index,1) ; 
      // })     
  // }
   
  if (loadError) return "Error";
  if (!isLoaded) return "Loading...";

  const quesDivStyle={
    fontfamily: "Raleway",
    width:'95vw',
    backgroundColor:"rgba(26, 179, 148, 0.2)",
    fontWeight: '700',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    marginBottom:5,
		display: 'flex',
  	flexDirection:'column',
  	alignItems: 'center' , 
  	alignContent: 'center',
  	fontSize: 'calc(1px + 2vmin)',
  	boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
  	transition: '0.3s',
  	borderRadius: '5px',
    paddingBottom:'1vh',
	 }


  return (
    <div>
    <div style={quesDivStyle}>
    <h2 style={{margin:10}}>{questionText}</h2>
    <div style={{width:'90vw',
                display:'flex',
                flex:1,
                flexDirection:'row-reverse',
                alignItems:'baseline',
                justifyContent:'space-between'}}>
    <button className='customButton' onClick={mapCompleteHandler}>{hindi?"अगला":"Next"}</button>

    <button id='info' onClick={infoButtonClickHandler}> i</button>
      {/* <Locate panTo={panTo} /> */}
      <Search panTo={panTo} />
      </div>
      </div>

      <GoogleMap
        id="map"
        mapContainerStyle={mapContainerStyle}
        zoom={mapZoom}
        center={center}
        options={options}
        onClick={onMapClick}
        onLoad={onMapLoad}
        onZoomChanged={onMapZoomChanged}
        
      >
            {markers.map((marker, index) => (
          <Marker
            label={`${index?marker.pos:""}`}
            key={index}
            position={{ lat: marker.lat, lng: marker.lng }}
            onClick={(e)=>{handleMarkerClick(e,marker)} }
            icon={{
              url: `${index?'/park.svg':'/home.svg'}`,
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(30, 30),
              labelOrigin: new window.google.maps.Point(20, -10),
            }}
            draggable={true}
            onDragEnd={(e) => { onMarkerDragEnd(e, index); }}
          >
          </Marker>
        ))}

        {modalOpen && <Modal setOpenModal={setModalOpen} setMarkers={setMarkers} marker={selected} info={info} setInfo={setInfo} hindi={hindi}/>}

        <div id="zoom"><p>{mapZoom}</p></div>

      </GoogleMap>
    </div>
  );
} 

function Locate({ panTo }) {
  return (
    <button
      className="locate"
      onClick={() => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            panTo({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          },
          () => null
        );
      }}
    >
      <img style={{height:'1em',width:'1em'}}  src="/compass.svg" alt="compass" />
    </button>
  );
}

function Search({ panTo }) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () =>23.259933, lng: () => 77.412613 },
      radius: 100 * 1000,
    },
  });

  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      panTo({ lat, lng });
    } catch (error) {
      console.log("😱 Error: ", error);
    }
  };

  return (
    <div className="search">
      <Combobox onSelect={handleSelect}>
        <ComboboxInput
          value={value}
          onChange={handleInput}
          disabled={!ready}
          placeholder="Search your location"
        />
        <ComboboxPopover>
          <ComboboxList>
            {status === "OK" &&
              data.map(({ id, description }) => (
                <ComboboxOption key={id} value={description} />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </div>
  );
}
